<template>
	<section class="main-content news-section">
		<div class="row row-header align-center">
			<h2 class="subtitle">{{ defaults[locale].homepage.sectionNewsTitle }}</h2>
			<div v-parse-links v-html="defaults[locale].homepage.sectionNewsContent" />
		</div>
		<div class="row flex-row">
			<div class="flex-row column6">
				<div v-for="item in news.slice(0, 1)" :key="item.ID" class="block-item white first-item">
					<nuxt-link :to="localePath(`/${item.filename}`)">
						<div class="block-item-content">
							<span class="event-date">
								{{ $d(new Date(item.date.date.split(' ')[0]), 'short', locale) }}
							</span>
							<h3>{{ item.title || item.header }}</h3>
							<div v-parse-links v-html="item.overviewContent" />
							<span class="button-no-fill">
								{{ defaults[locale].website.buttonReadMore }}
							</span>
						</div>
						<picture>
							<source v-if="item.overviewImageWebp" :srcset="item.overviewImageWebp" type="image/webp" />
							<source :srcset="item.overviewImage" />
							<img loading="lazy" :src="item.overviewImage" :alt="item.overviewImageAlt" />
						</picture>
					</nuxt-link>
				</div>
			</div>
			<div class="flex-row column6">
				<div v-for="item in news.slice(1, 5)" :key="item.ID" class="block-item white">
					<nuxt-link :to="localePath(`/${item.filename}`)">
						<div class="block-item-content">
							<span class="event-date">
								{{ $d(new Date(item.date.date.split(' ')[0]), 'short', locale) }}
							</span>
							<h3>{{ item.title || item.header }}</h3>
							<div v-parse-links v-html="item.overviewContent" />
							<span class="button-no-fill">
								{{ defaults[locale].website.buttonReadMore }}
							</span>
						</div>
						<picture>
							<source v-if="item.overviewImageWebp" :srcset="item.overviewImageWebp" type="image/webp" />
							<source :srcset="item.overviewImage" />
							<img loading="lazy" :src="item.overviewImage" :alt="item.overviewImageAlt" />
						</picture>
					</nuxt-link>
				</div>
			</div>
		</div>
	</section>
</template>

<script setup>
const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();

await fetchDefaults();

defineProps({
	news: { type: Array, default: () => [] },
});
</script>

<style lang="scss" scoped>
.news-section {
	background: url('~/assets/images/shapes/news-top.png') no-repeat right top;
}

.block-item {
	overflow: hidden;

	&.first-child {
		border-radius: 45px 0 0 45px;
	}

	&:nth-child(2) {
		border-radius: 0 45px 0 0;
	}

	&:nth-child(4) {
		border-radius: 0 0 45px;
	}
}

.block-item-content {
	color: #fff;

	p,
	:deep(p) {
		color: #fff !important;
	}
}

.event-date {
	color: #fff;
	font-size: 14px;
	margin: 0 0 10px;
	display: inline-block;
	width: 100%;
	letter-spacing: 2px;
}

@media (max-width: 1380px) {
	.column6:first-child .block-item-content {
		padding: 30px 40px;
	}

	.block-item-content {
		padding: 20px;

		p,
		:deep(p) {
			line-height: 20px;
		}
	}

	.block-item:hover {
		p,
		:deep(p) {
			margin: 10px 0;
			height: 70px;
		}
	}
}
</style>
