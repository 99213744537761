<template>
	<page-container :page="page">
		<heading-image
			v-if="page.headingImage"
			:heading-image="{
				image: page.headingImage,
				imageWebp: page.headingImageWebp,
				imagePortrait: page.headingImagePortrait,
				imagePortraitWebp: page.headingImagePortraitWebp,
			}"
		>
			<template #heroContent>
				<div v-if="defaults[locale].homepage.headingTitle" class="hero-content">
					<span class="hero-title">
						{{ defaults[locale].homepage.headingTitle }}
					</span>
					<span class="hero-subtitle">
						{{ defaults[locale].homepage.headingSubTitle }}
					</span>
				</div>
				<div class="shape shape-header" />

				<booking-form :booking-form="defaults[locale].bookingForm" />
			</template>
		</heading-image>

		<main-content
			:title="page.title || page.header"
			:subtitle="page.subtitle"
			:content="page.content"
			:introduction-content="page.introductionContent"
		/>

		<booking-benefits
			:title="defaults[locale].homepage.sectionBenefitsTitle"
			:subtitle="defaults[locale].homepage.sectionBenefitsSubtitle"
			:benefits="defaults[locale].homepage.bookingBenefits"
			:book-button="defaults[locale].website.book"
			:image="{
				image: defaults[locale].homepage.sectionBenefitsImage,
				imageAlt: defaults[locale].homepage.sectionBenefitsImageAlt,
				imageWebp: defaults[locale].homepage.sectionBenefitsImageWebp,
			}"
		/>

		<promoblocks
			:header="defaults[locale].homepage.sectionPromotionTitle"
			:subtitle="defaults[locale].homepage.sectionPromotionSubtitle"
			:promoblocks="promoblocksData"
			:amount="4"
		/>

		<contentblocks
			v-if="page && page.contentblocks && page.contentblocks.length"
			:contentblocks="page.contentblocks"
			:content-blocks-content="page.sectionContentblocksContent"
			:content-blocks-subtitle="page.sectionContentblocksTitle"
		/>

		<rooms-promotions
			:items="roomsData"
			:header="defaults[locale].rooms.sectionRoomsTitle"
			:subtitle="defaults[locale].rooms.subtitle"
			:content="defaults[locale].rooms.sectionRoomsContent"
			:button="defaults[locale].rooms.view"
		/>

		<newsletter
			v-if="page && page.showNewsletter"
			:header="defaults[locale].newsletter.title"
			:content="defaults[locale].newsletter.content"
			:button="defaults[locale].newsletter.button"
		/>

		<frequently-asked-questions
			v-if="page && page.frequentlyAskedQuestion"
			class="faq-section"
			:header="page.frequentlyAskedQuestion.header"
			:subtitle="page.frequentlyAskedQuestion.subtitle"
			:items="page.frequentlyAskedQuestion.faqitems"
		/>

		<news-overview :news="news" />
		<pre-footer
			v-if="page && page.footerSection && page.footerSection.length"
			:footer-section="page.footerSection[0]"
		/>
	</page-container>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();

await fetchPage();
await fetchDefaults();

const { data: promoblocksData } = await useWebsiteFetch('promoblocks', {
	query: { language: locale.value },
	key: `${locale.value}/promoblocks`,
});

const { data: news } = await useWebsiteFetch('news', {
	query: { language: locale.value },
	key: `${locale.value}/news`,
});

const { data: roomsData } = await useWebsiteFetch('rooms', {
	query: { language: locale.value },
	key: `${locale.value}/rooms`,
});
</script>

<style lang="scss" scoped>
.hero-content {
	position: absolute;
	top: 45%;
	left: 0;
	right: 0;
	z-index: 3;
	text-align: center;
	margin: 0 auto;
	max-width: 700px;
	padding: 0 20px;
	color: #fff;
	animation: fade-from-bottom 1.4s ease;

	span {
		color: #fff;
		font-weight: 700;
		font-size: 70px;
		letter-spacing: 1px;
		width: 100%;
		font-family: var(--heading-font-family);
		display: inline-block;
		margin: 0 0 10px;
	}

	.hero-subtitle {
		font-size: 24px;
		font-weight: 300;
		font-family: var(--body-font-family);
	}
}

.shape-header {
	height: 47vw;
	position: absolute;
	top: 0;
	right: 0;
	width: 33vw;
	background: url('~/assets/images/shapes/heading-big.png') no-repeat center left;
	background-size: cover;
	z-index: 2;
}

@media (max-width: 800px) {
	.hero-content {
		top: auto;
		bottom: 35%;
	}
}

@media (max-height: 700px) {
	.hero-content {
		span {
			font-size: 56px;
		}
	}
}

@media (max-height: 820px) {
	.hero-content {
		top: 43%;
	}
}

@media (max-height: 600px) {
	.hero-content {
		span {
			display: none;
		}
	}
}

@media (max-width: 1280px) {
	.shape-header {
		height: 76vh;
		width: 54vh;
		background-position: center center;
	}

	.hero-content {
		span {
			font-size: 50px;
		}
	}
}

@media (max-width: 1100px) {
	.qr-section {
		display: none;
	}

	.shape-header {
		display: none;
	}
}

@media (max-width: 880px) {
	.hero-content {
		span {
			font-size: 35px;
		}
	}
}

@media (max-width: 680px) {
	.hero-content {
		span {
			font-size: 44px;
		}

		.hero-subtitle {
			font-size: 22px;
		}
	}
}

@media (max-width: 520px) {
	.hero-content {
		span {
			font-size: 40px;
		}

		.hero-subtitle {
			font-size: 20px;
		}
	}
}
</style>
