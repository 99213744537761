<template>
	<section ref="qrPanel" class="qr-section">
		<client-only>
			<div class="qr-panel">
				<div class="row">
					<form data-guestline-form class="booking bookForm">
						<div class="columns column4">
							<VueDatePicker
								ref="arrival"
								v-model="date"
								auto-apply
								:min-date="new Date()"
								:clearable="false"
								:disabled-dates="disableArrivalDates"
								:enable-time-picker="false"
								name="arrival"
								uid="arrival"
								:placeholder="$t('checkIn')"
								@closed="openDeparturePicker"
							/>
						</div>
						<div class="columns column4">
							<VueDatePicker
								ref="datepickerDeparture"
								v-model="dateDeparture"
								auto-apply
								:open="state.openDeparture"
								:disabled-dates="disableDepartureDates"
								:enable-time-picker="false"
								:clearable="false"
								name="departure"
								uid="departure"
								:placeholder="$t('checkOut')"
								:min-date="date"
								:start-date="date"
							/>
						</div>
						<div class="columns column4">
							<input type="submit" class="button book-now cta qr" :value="$t('checkAvailability')" />
						</div>
					</form>
				</div>
			</div>
		</client-only>
	</section>
</template>

<script setup>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

defineProps({
	bookingForm: { type: Object, default: () => ({}) },
});

const state = reactive({
	arrival: null,
	departure: null,
	openDeparture: false,
	pickedArrivalDate: null,
});

const qrPanel = ref(null);

const datepickerDeparture = ref(null);

const date = ref(null);

const dateDeparture = ref(null);

const disableArrivalDates = (date) => {
	const today = new Date();

	return date < today.setDate(today.getDate() - 1);
};

const openDeparturePicker = () => {
	state.pickedArrivalDate = date;
	datepickerDeparture.value.toggleMenu();
};

const disableDepartureDates = (date) => {
	if (!state.pickedArrivalDate) {
		return disableArrivalDates(date);
	}

	return date <= state.pickedArrivalDate;
};

onMounted(() => {
	qrPanel.value.classList.add('mounted');
});
</script>

<style>
.mx-datepicker {
	width: 100%;
}

#dp-input-departure,
#dp-input-arrival {
	margin: 3px 0;
	width: 100%;
	padding: 15px 40px 15px 0;
	height: 50px;
	border: none;
	border-radius: 0;
	border-bottom: 4px solid var(--header-color);
	background: none;
	box-shadow: none;
	color: var(--body-color);
	font-weight: 400;
	font-size: 18px;
}

::placeholder {
	color: var(--body-color);
}

/* stylelint-disable selector-class-pattern */
.dp__input_icon {
	right: 0;
	padding: 6px 0;
	inset-inline-start: auto;
}

.dp__main,
.dp__menu {
	font-family: var(--body-font-family) !important;
}

.dp__menu {
	font-size: 14px;
}

.dp__today {
	border-color: var(--cta-color);
}

.dp__range_end,
.dp__range_start,
.dp__active_date {
	background: var(--cta-color);
}
/* stylelint-enable selector-class-pattern */
</style>

<style lang="scss" scoped>
.qr-section {
	text-align: center;
	margin: 0 auto;
	position: absolute;
	bottom: 150px;
	left: 0;
	right: 0;
	padding: 0;
	float: left;
	width: 100%;
	max-width: 920px;
	z-index: 10;
}

.qr-panel {
	float: none;
	padding: 10px 0;
	width: 100%;
	margin: 0 auto;
	display: inline-block;
	position: relative;
	z-index: 2;
	text-align: center;
	background: #fff5f0;
	border-radius: 100px;

	.row {
		width: 100%;
		overflow: visible;

		&:last-child {
			padding: 0 20px;
			font-size: 14px;

			.column12 {
				text-align: left;
			}

			span {
				margin: 0 30px 0 0;
			}
		}
	}

	.columns {
		padding: 0;
	}

	form {
		width: 100%;
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: space-between;

		.columns {
			padding: 10px 20px;
			width: 29%;

			&:last-child {
				width: 42%;
			}
		}
	}
}

.qr-panel input,
.qr-panel select {
	margin: 3px 0 !important;
	width: 100% !important;
	padding: 15px 40px 15px 0 !important;
	height: 64px !important;
	border-bottom: 4px solid var(--header-color) !important;
}

.qr-panel .button {
	padding: 14px 10px !important;
	text-transform: uppercase;
	border: none !important;
	vertical-align: top;
}

.benefits {
	font-size: 13px;
	font-weight: 500;

	svg {
		color: var(--header-color);
		margin: 0 5px 0 0;
	}

	strong {
		color: var(--header-color);
	}
}

@media (max-width: 1200px) {
	.qr-section {
		max-width: 800px;

		.button {
			font-size: 14px;
		}

		.qr-panel form .columns {
			padding: 5px 10px;
		}
	}
}

@media (max-height: 880px) {
	.qr-section {
		bottom: 90px;
	}
}
</style>
